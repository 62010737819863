import React from 'react'
import PropTypes from 'prop-types'
import Section from 'src/components/Section'
import Typography from 'src/components/Typography'
import Container from 'src/components/Container'
import { Box } from '@material-ui/core'
import Markdown from 'src/components/Markdown'
import { graphql } from 'gatsby'

const ContentPage = props => {
  const { title, text } = props

  return (
    <Section border={false} color="transparent">
      <Container>
        <Box mx="auto" mb={60 / 8}>
          <Typography variant="hero" paragraph>
            {title}
          </Typography>
          <Typography variant="body">
            <Markdown source={text} />
          </Typography>
        </Box>
      </Container>
    </Section>
  )
}

ContentPage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

ContentPage.defaultProps = {
  //
}

export default ContentPage

export const query = graphql`
  fragment ContentPageFragment on pages {
    title
    text
    metaTitle
    metaDescription
    metaImage {
      local {
        childImageSharp {
          fixed(width: 1024, quality: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
