import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/layout'
import ContentPage from 'src/regions/ContentPage'

const Datenschutz = props => {
  const {
    pages: { nodes: pages },
  } = props.data

  const { title, text, metaTitle, metaDescription, metaImage } = pages[0]

  const metaProps = {
    metaTitle,
    metaDescription,
    metaImage,
  }

  return (
    <Layout meta={metaProps}>
      <ContentPage title={title} text={text} />
    </Layout>
  )
}

export default Datenschutz

export const query = graphql`
  query {
    pages: allPages(filter: { id__normalized: { eq: "datenschutz" } }) {
      nodes {
        ...ContentPageFragment
      }
    }
  }
`
